<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'company/list'"
          :headers="companyHeaders"
          :title="title"
          :sortBy="sortBy"
          :sortDesc="sortDesc"
          :fieldClickable="fieldClickable"
          icon="mdi-briefcase-download"
          tableType="company"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
export default {
  components: {
    Table
  },
  data: function() {
    return {
      sortBy: ["reg_date"],
      sortDesc: [true],
      fieldClickable: {
        field: "org_name",
        to: "/manager/company/list",
        key: "company_id"
      }
    };
  },
  computed: {
    title() {
      return this.$vuetify.lang.t("$vuetify.cards.companys")
    },
    companyHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.tables.reg_id"),
          value: "reg_id"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.name"),
          value: "name"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.company.production_field"),
          value: "main_production_field"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.id_code"),
          value: "id_code"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.prefix"),
          value: "prefix"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.reg_date"),
          value: "reg_date"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.payed_to"),
          value: "payed_to"
        },
        {
          text: "",
          value: "removeCompany",
          sortable: false,
        },
      ];
    }
  }
};
</script>